import * as React from "react";
import "./chart.scss";
import { calculateColor, formatPercent, heatmapData } from "./data/heatmap";
import { regions } from "./data/regions";

const { useState, useEffect } = React;
export const UsaMapChart: React.FunctionComponent = () => {
  const [expandedIndex, setExpandedIndex] = useState(-1);

  const updateExpander = (index: number) => {
    if (expandedIndex === index) {
      setExpandedIndex(-1);
    } else {
      setExpandedIndex(index);
    }
  };

  useEffect(() => {
    const match = window.location.hash.match(/#(US-.*)/i);
    if (match) {
      const found = regions.findIndex(
        (region) => region.id.toUpperCase() === match[1].toUpperCase()
      );
      setExpandedIndex(found);
    }
  }, []);
  return (
    <div className="prep-uptake">
      {regions.map((region, index) => {
        const classNames = [
          "expander",
          expandedIndex === index && "expander--expanded",
        ];
        const states = region.children
          .map((id) => {
            const found = heatmapData.find((state) => state.id === id);
            if (found) {
              return found;
            }
          })
          .filter((x) => x)
          .sort((a, b) => compareStrings(a?.name, b.name));
        return (
          <div
            key={region.id}
            className={classNames.filter((x) => x).join(" ")}
          >
            <button
              className="w-full"
              data-region-id={region.id}
              onClick={() => updateExpander(index)}
            >
              <div
                className={`bg-red text-white expander--button expander--${region.id}`}
              >
                {region.name}
              </div>
            </button>
            <div className="expander--inner">
              <div>
                <table>
                  <tbody key={region.id}>
                    <tr className="grey">
                      <th>State</th>
                      <th>
                        PrEP uptake among persons aged{" "}
                        <span style={{ fontWeight: "normal" }}>
                          ≥<span className="mini-space">&nbsp;</span>
                        </span>
                        16 years*
                      </th>
                    </tr>
                    {states.map((data) => {
                      if (!data) {
                        return null;
                      }
                      return (
                        <tr key={data.id}>
                          <td>{data.name}</td>
                          <td className="text-center">
                            {formatPercent(data.rate)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default UsaMapChart;

function compareStrings(lhs: string, rhs: string) {
  const a = lhs.toUpperCase();
  const b = rhs.toUpperCase();
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
}
